import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from '@core/environments/environment';
import { TOKEN } from 'app/app.constants';
import { Observable, finalize } from 'rxjs';
import { LoadingService } from './loading.service';
import { URL_KEYS } from '@core/constants/url-keys.constants';

@Injectable({
  providedIn: 'root',
})
export class IndividualRegistrationService {
  http = inject(HttpClient);
  private loadingService = inject(LoadingService);

  ADMIN_BASE_URL = `${environment.ADMIN_BASE_URL}/v1`;
  KYC_BASE_URL = `${environment.ADMIN_BASE_URL}/v1/account/create-individual-account`;

  constructor() {}

  createCustomer(formData: CreateCustomer): Observable<CreateCustomerResponse> {
    const uniqueStateUrl = 'create-individual-user';
    this.loadingService.setLoading(uniqueStateUrl, true);

    formData.marital_status = formData.marital_status.toLowerCase();
    return this.http
      .post<CreateCustomerResponse>(
        `${this.ADMIN_BASE_URL}/admin/create-user`,
        formData
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateCustomerProfile(payload: {
    user_identifier: string;
    first_name?: string;
    last_name?: string;
    middle_name?: string;
    phone_number_1?: string;
    phone_number_2?: string;
    annual_income?: string;
    source_of_fund?: string;
    bvn?: string;
    dob?: string;
    state_of_origin?: string;
    nationality?: string;
    marital_status?: string;
    gender?: string;
    profile_id: string;
  }) {
    const uniqueStateUrl =
      URL_KEYS.INDIVIDUAL_REGISTRATION.UPDATE_CUSTOMER_PROFILE;
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/admin/update-profile`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  addCustomerLocation(formData: any): Observable<any> {
    const uniqueStateUrl = 'create-location-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/create-location-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  updateLocationDetails(payload: {
    country: string;
    state: string;
    city: string;
    // house_number: string;
    address: string;
    citizenship_status: string;
    second_country: string;
    residency_status: string;
    residence_permit: string;
    permit_issue_date: string;
    permit_expiry_date: string;
    account_id: string;
  }): Observable<unknown> {
    const uniqueStateUrl = 'update-location-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/update-location-kyc`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  /**
   * Adds a means of identity for KYC (Know Your Customer) verification.
   *
   * @param {Object} formData - The form data containing the means of identity details.
   * @param {string} formData.type - The type of identity document.
   * @param {string} formData.number - The document number.
   * @param {string} formData.issue_date - The date of issue of the document.
   * @param {string} formData.expiry_date - The expiry date of the document.
   * @param {string} formData.account_id - The ID of the account.
   * @returns {Observable<any>} An observable containing the server's response.
   */
  addMeansOfId(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-means-of-identity-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/add-means-of-identity-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  updateMeansOfIdentification(payload: {
    type: string;
    number: string;
    issue_date: string;
    expiry_date: string;
    account_id: string;
  }): Observable<unknown> {
    const uniqueStateUrl = 'update-means-of-identity-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/update-means-of-identity-kyc`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  addEmploymentDetails(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-employment-details';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/add-employment-details`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  updateEmploymentDetails(payload: any): Observable<unknown> {
    const uniqueStateUrl = 'update-employment-details';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/update-employment-details`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  addNextOfKin(formData: any): Observable<any> {
    const uniqueStateUrl = 'add-next-of-kin-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);
    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/add-next-of-kin-kyc`, formData)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  updateNextOfKin(formData: any): Observable<unknown> {
    const uniqueStateUrl = 'update-next-of-kin-kyc';
    this.loadingService.setLoading(uniqueStateUrl, true);

    const payload = Object.assign(formData, {
      gender: formData.gender.toLowerCase(),
    });

    return this.http
      .post(`${this.ADMIN_BASE_URL}/kyc/update-next-of-kin-kyc`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }
  addBankDetails(formData: any): Observable<CreateBankDetailsResponse> {
    const uniqueStateUrl = 'add-banking-details-to-account';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post<CreateBankDetailsResponse>(
        `${this.ADMIN_BASE_URL}/account/add-banking-details-to-account`,
        formData
      )
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  updateBankDetails(payload: {
    id: string;
    bank_account_name: string;
    bank_account_number: string;
    bank_code: string;
    bank_branch: string;
    bank_sort_code: string;
    currency: string;
  }): Observable<unknown> {
    const uniqueStateUrl = 'update-banking-details';
    this.loadingService.setLoading(uniqueStateUrl, true);

    return this.http
      .post(`${this.ADMIN_BASE_URL}/account/update-banking-details`, payload)
      .pipe(
        finalize(() => this.loadingService.setLoading(uniqueStateUrl, false))
      );
  }

  // this moves to a different service
  documents() {}
}

export interface CreateCustomer {
  first_name: string;
  last_name: string;
  title: string;
  email: string;
  phone_number_1: string;
  phone_number_2: string;
  dob: Date;
  state_of_origin: string;
  marital_status: string;
  nationality: string;
  annual_income: string;
  bvn: string;
}

export interface Location {
  country: string;
  state: string;
  city: string;
  house_number: string;
  citizenship_status: string;
  second_country: string;
  residency_status: string;
  residence_permit: string;
  permit_issue_date: string;
  permit_expiry_date: string;
}

interface CreateCustomerResponse {
  status: string;
  message: string;
  profile_id: string;
}

export interface CreateBankDetailsResponse {
  status: string;
  message: string;
  data: CreateBankDetailsDatum[];
}

interface CreateBankDetailsDatum {
  bank_account_name: string;
  bank_account_number: string;
  bank_code: string;
  bank_sort_code: string;
  bank_branch: string;
  id: string;
  updated_at: string;
  created_at: string;
}
